.noselect {
  -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
     -khtml-user-select: none !important; /* Konqueror HTML */
       -moz-user-select: none !important; /* Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
            user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#dimScreen {
  width: 100%;
  height: 100%;
  background:rgba(255,255,255,0.75); 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; /* Just to keep it at the very top */
}

#floatingText {
  text-align: center;
  margin-top: 10%;
}